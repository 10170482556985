import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import UserPanel from './UserPanel';
import AuthService from '../service/AuthService';
import { MODAL } from '../main/ModalController';
import { LABELS } from '../Labels';
import { Link } from 'react-router-dom';

const ENV = process.env.REACT_APP_BUILD_ENV==='production'?'prod':'dev';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width:window.innerWidth
        }
    }
    async componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
         this.setState({width:window.innerWidth});
    }



    render() {
        let lang = this.props.exchange.language || 'de';
        let isSmall = isMobile || this.state.width<750;
        let isLoggedIn = this.props.exchange.user!=null;
        return (
            <div className={isMobile?'header-comp-mobile':'header-comp'}> 
                <div className='hdr-main' style={{display:'flex',height:'100%',alignItems:'center',justifyContent:'space-between'}}>
                 
                    <Link style={{minWidth:'fit-content',textDecoration:'none', color:'black',paddingRight:'16px'}} to="/">
                        <h2 className={isSmall?'header-logo-mobile':'header-logo'}>Medcomply</h2>
                    </Link>
                    {/* <p style={{position:'absolute'}}>{this.state.width}</p> */}
                    {!isSmall && 
                    <div style={{display:'flex',width:'100%'}}>
                        {/* <button className='hdr-sec-btn'>{LABELS.PRODUCT[lang]}</button>
                        <button className='hdr-sec-btn'>{LABELS.PRICES[lang]}</button>
                        <button className='hdr-sec-btn'>{LABELS.CONTACT[lang]}</button>
                        <button className='hdr-sec-btn'>{LABELS.PRACTICE[lang]}</button> */}
                    </div>}
                    <div className='hrd-controls'>
                        {isLoggedIn?
                        <div className='user-header-wraper' style={{display:'flex',alignItems:'center'}}>
                            <Link className='hdr-sec-btn go-to-dboard-btn' to='/'>{LABELS.GOTO_DASHBOARD[lang]}</Link>
                            <button className='hdr-sec-btn go-to-dboard-btn' style={{width:'100px'}} onClick={()=>AuthService.logout(null, true)} >
                                <p style={{fontWeight:'500'}}>{LABELS.LOGOUT[lang]}</p>
                            </button>
                            <UserPanel exchange={this.props.exchange}/>
                        </div>:
                        <div className='hrd-login-control' style={{display:'flex'}}>
                        
                    {/* UNCOMMENT ON GO LIVE!!! */}
                            {ENV!=='prod' &&
                            <button 
                                className={'btn1 btn1-second ' + (isSmall?'hbtn-mobile':'hbtn')} 
                                onClick={()=>this.props.exchange.setModal(MODAL.LOGIN)}>
                                {LABELS.LOGIN[lang]}
                            </button>}
                            {ENV!=='prod' &&
                            <button className={'btn1 ' +(isSmall?'hbtn-mobile':'hbtn')} 
                                onClick={()=>this.props.exchange.setModal(MODAL.REGISTER)}>
                                {LABELS.OPEN_ACCOUNT[lang]}
                            </button>}
                    {/* UNCOMMENT ON GO LIVE!!! */}


                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;