import React, { Component } from 'react';
import './pages.css';
import { Link } from 'react-router-dom';
import { MODAL } from '../main/ModalController';
import { LABELS } from '../Labels';
import './LandingPage.css'
import { isMobile } from 'react-device-detect';
import LandingPage_Pic1 from '../assets/LandingPage_Pic1.jpeg'
import LandingPage_Pic2 from '../assets/LandingPage_Pic2.jpeg'
import LandingPage_Pic3 from '../assets/LandingPage_Pic3.jpeg'
import LandingPage_Pic4 from '../assets/LandingPage_Pic4.jpeg'
import landingbg from '../assets/landingbg.png'
import {generateDocuPdf} from '../main/document-generator/pdf_generator';

const ENV = process.env.REACT_APP_BUILD_ENV==='production'?'prod':'dev';
class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width:window.innerWidth
        }
    }
    async componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
         this.setState({width:window.innerWidth});
    }

    render() {
        let lang = this.props.exchange.language || 'de';
        let isSmall = isMobile || this.state.width<750;
        return (
            <div className='landing-page' style={{backgroundColor:'white',minHeight:'100vh'}}>
                <div style={{padding:isSmall?'4vw 4vw':'2.6vw 12vw',backgroundBlendMode: 'lighten',
                backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center',
                backgroundImage:'url('+landingbg+')'
                // backgroundImage:'url(https://img.freepik.com/premium-vector/white-background-with-line-texture_476151-733.jpg?w=2000)'
                }}>
                     <br/><br/><br/>
                    <div className='slo-wrap' style={{width:'80%'}}>
                        <h1 className={isSmall?'slogan-1-mobile':'slogan-1'}>Medcomply</h1>
                        <p style={{fontSize:isSmall?'1.7rem':'2rem',fontWeight:'300'}}>compliance with ease</p>
                    </div>
                    <br/><br/><br/>
                </div>

                <div style={{padding:isSmall?'4vw 4vw':'38px 12vw'}}>
                    <div style={{display:'flex',width:'100%'}}>
                        <div className='left-dd' style={{width:'55%',display:'flex',justifyContent:'flex-end'}}>
                            <div  style={{ paddingRight:isSmall?'1wv':'2vw'}}>
                                <div className='info-block-bl-left'  style={{maxWidth:'380px',height:'400px'}}>
                                    <h1 style={{fontSize:isSmall?'2rem':'2.9rem',fontWeight:'900',textAlign:'right',cursor:'default'}}>Datenschutz in der Arztpraxis</h1>
                                    <br/>
                                    <p style={{fontSize:'1.2rem',textAlign:'right',cursor:'default'}}>Patienten merken sofort, ob Datenschutz in einer Praxis gelebt wird und ob die Daten sicher verwaltet werden. Gleichzeitig müssen Patienten verstehen, dass die Bearbeitung und Weitergabe ihnen hilft und nötig ist. Mit Medcomply erhalten Sie Zugang zu einer leicht verständlichen Lösung und machen die Praxis fit für das neue Datenschutzgesetz.</p>
                                </div>
                            </div>
                        </div>
                        <div className='right-dd' style={{width:'45%'}}>
                            <div className='wphoto-main' style={{height:'400px'}}>
                                <div className='wphoto-scr-img' style={{display:'flex',width:'100%',height:'100%'}}>
                                    <img class='wphoto-scr-img' style={{width:'100%',objectFit:'cover',borderRadius:'12px'}} alt='' src={LandingPage_Pic1}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/> <br/> <br/>
                    <div style={{display:'flex',width:'100%'}}>
                        <div className='right-dd' style={{width:'50%'}}>
                            <div className='wphoto-main' style={{height:'400px',paddingRight:'2vw'}}>
                                <div className='wphoto-scr-img' style={{display:'flex',width:'100%',height:'100%'}}>
                                    <img class='wphoto-scr-img' style={{width:'100%',objectFit:'cover',borderRadius:'12px'}} alt='' src={LandingPage_Pic2}/>
                                </div>
                            </div>
                        </div>

                        <div className='left-dd' style={{width:'55%'}}>
                            <div className='welcome-login-component' style={{paddingRight:isSmall?'4vw':'13vw'}}>
                                <div className='info-block-right' style={{maxWidth:'380px',height:'400px'}}>
                                    <h1 style={{fontSize:isSmall?'2rem':'2.9rem',fontWeight:'900',cursor:'default'}}>Wieso Datenschutz - management</h1>
                                    <br/>
                                    <p style={{fontSize:'1.2rem',cursor:'default'}}>Konformität mit Datenschutz ist leichter gesagt als getan. Medcomply hilft der Praxis mit einem System, welches Sie oder ihre Praxisasssitenten durch die wichtigsten Fragen hindurchführt. Das ist Medcomply, compliance with ease.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/> <br/> <br/>
                    <div style={{display:'flex',width:'100%'}}>
                        <div className='left-dd' style={{width:'55%'}}>
                            <div className='welcome-login-component' style={{paddingLeft:isSmall?'3vw':'11vw',paddingRight:isSmall?'1wv':'2vw'}}>
                                <div className='info-block-left'  style={{maxWidth:'380px',height:'400px'}}>
                                    <h1 style={{fontSize:isSmall?'2.2rem':'2.9rem',fontWeight:'900',textAlign:'right',cursor:'default'}}>Leistungen</h1>
                                    <br/>
                                    <p style={{fontSize:'1.2rem',textAlign:'right',cursor:'default'}}>Medcomply bietet Zugang zu den wichtigsten Dokumenten im Datenschutz, und die Lösung wird laufend weiterentwickelt. Nach Analyse und Eingabe ihrer Daten erhalten Sie eine massgeschneiderte Datenschutzlösung, verwalten die Outsourcing Partner und wissen, was bei Problemen zu tun ist.</p>
                            </div>
                            </div>
                        </div>
                        <div className='right-dd' style={{width:'50%'}}>
                            <div className='wphoto-main' style={{height:'400px'}}>
                                <div className='wphoto-scr-img' style={{display:'flex',width:'100%',height:'100%'}}>
                                    <img class='wphoto-scr-img' style={{width:'100%',objectFit:'cover',borderRadius:'12px'}} alt='' src={LandingPage_Pic3}/>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <br/> <br/> <br/>
                    <div style={{display:'flex',width:'100%'}}>
                        <div className='right-dd' style={{width:'50%'}}>
                            <div className='wphoto-main' style={{height:'100%',paddingRight:'2vw'}}>
                                <div className='wphoto-scr-img' style={{display:'flex',width:'100%',height:'100%'}}>
                                    <img class='wphoto-scr-img' style={{width:'100%',objectFit:'cover', height:'400px',borderRadius:'12px'}} alt='' src={LandingPage_Pic4}/>
                                </div>
                            </div>
                        </div>

                        <div className='left-dd' style={{width:'55%'}}>
                            <div className='welcome-login-component' style={{paddingRight:isSmall?'4vw':'13vw'}}>
                                <div className='info-block-right'  style={{maxWidth:'380px',height:'400px'}}>
                                    <h1 style={{fontSize:isSmall?'2.2rem':'2.9rem',fontWeight:'900',cursor:'default'}}>Kosten</h1>
                                    <br/>
                                    <p style={{fontSize:'1.2rem',cursor:'default'}}>Der Zugang zu Medcomply kostet jährlich CHF 100. Mit diesem Betrag erhalten Sie unbeschränkten Zugang für ein Jahr zu allen Featues von Medcomply und profitieren von den künftigen Weiterentwicklungen. Sie werden mit Medcomply immer Up-to-date bleiben.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div style={{display:'flex',width:'100%',padding:isSmall?'2vh 4vw':'5vh 12vw',backgroundColor:'rgb(30,30,30)'}}>
                    <div className='left-dd' style={{width:'50%',color:'white'}}>
                        <div className='welcome-login-component' style={{paddingRight:isSmall?'4vw':'13vw'}}>
                            <h1 style={{fontSize:isSmall?'2rem':'2.4rem',cursor:'default'}}>Fragen?</h1>
                            <br/>
                            <p style={{fontSize:'1rem',cursor:'default'}}>Rufen Sie uns an unter +41445215130 order schicken Sie uns ein Mail an info@medcomply.ch</p>
                        </div>
                    </div>
                </div>

                <br/> <br/>
                <div style={{padding:isSmall?'2vw 4vw':'2vw 12vw',textAlign:'center',backgroundImage:'url('+landingbg+')', backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'center'}}>
                    {/* <div style={{borderTop:'solid 1px rgb(140,140,140)'}}></div> */}
                    <br/><br/>
                    <p style={{fontWeight:'300',fontSize:'2rem'}}>Erhalten Sie Zugang zu einer leicht verständlichen Lösung und machen Sie Ihre Praxis fit für das neue Datenschutzrecht.</p>
                    <br/> <br/>

                    {/* UNCOMMENT ON GO LIVE!!! */}
                    {ENV!=='prod' &&
                    <Link className='btn1' style={{fontSize:'1.2rem', textDecoration:'none',  fontWeight:'200'}} 
                        onClick={()=>{this.props.exchange.setModal(MODAL.REGISTER)}}
                        >Jetzt registrieren</Link>}
                    {/* UNCOMMENT ON GO LIVE!!! */}

                    <br/><br/><br/>
                    <div style={{borderTop:'solid 1px rgb(140,140,140)'}}></div>
                </div>

               
                <div style={{padding:'6vh 1vw 4vh 1vw',textAlign:'center'}}>
                    <h1>Medcomply</h1>
                    <p>© 2024</p>
                </div>
                
            </div>
        );
    }
}

export default LandingPage;