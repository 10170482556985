import React, { Component } from 'react';
import CloseableModal from '../components/CloseableModal';
import SignupPage from '../pages/loginsignup/SignupPage';
import ComingSoon from '../pages/announcements/ComingSoon';
import PasswordChangeSuccess from '../pages/loginsignup/PasswordChangeSuccess';
import MasterDataUpdatedSuccess from '../pages/MasterDataUpdatedSuccess';

import LoginPage from '../pages/loginsignup/LoginPage';
import VerificationEmailSent from '../pages/loginsignup/VerificationEmailSent';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import InitialMasterDataSetup from '../pages/loginsignup/InitialMasterDataSetup';
import LanguageSet from '../pages/LanguageSet';

export const MODAL= Object.freeze({
      NONE:'NONE'
     ,LOGIN:'LOGIN'
     ,REGISTER:'REGISTER'
     ,SIGNUP_SUCCESS:'SIGNUP_SUCCESS'
     ,FORGOT_PASSWORD:'FORGOT_PASSWORD'
     ,SET_INITIAL_INFO:'SET_INITIAL_INFO'
     ,SET_LANGUAGE:'SET_LANGUAGE'
     ,MAINTENANCE:'MAINTENANCE'
     ,PASSWORD_CHANGE_SUCCESS:'PASSWORD_CHANGE_SUCCESS'
     ,MASTER_DATA_UPDATED:'MASTER_DATA_UPDATED'
  });
class ModalController extends Component {

    renderModals=()=>{
        switch (this.props.modal) {
            case MODAL.LOGIN: 
                return <CloseableModal 
                            content={<LoginPage exchange={this.props.exchange}/>}
                            onCancel={()=>this.props.exchange.removeModal()}
                            />
            case MODAL.REGISTER: 
                return <CloseableModal 
                            content={<SignupPage exchange={this.props.exchange}/>}
                            onCancel={()=>this.props.exchange.removeModal()}
                            />
            case MODAL.SIGNUP_SUCCESS: 
                return <CloseableModal 
                            content={<VerificationEmailSent exchange={this.props.exchange} />}
                            onCancel={()=>this.props.exchange.removeModal()}
                            />
            case MODAL.FORGOT_PASSWORD: 
                return <CloseableModal 
                            content={<ForgotPasswordPage exchange={this.props.exchange} />}
                            onCancel={()=>this.props.exchange.removeModal()}
                            />
            case MODAL.NONE: return null;
            case MODAL.SET_LANGUAGE: 
                return <CloseableModal 
                            content={<LanguageSet exchange={this.props.exchange} />}
                            onCancel={()=>this.props.exchange.removeModal()}
                            />
            case MODAL.SET_INITIAL_INFO: 
                return <CloseableModal 
                            style={{}}
                            content={
                                <InitialMasterDataSetup 
                                    exchange={this.props.exchange}
                                    onSaveSuccessCallback={()=>this.props.exchange.setModal(MODAL.NONE)}
                                    />}
                            onCancel={()=>this.props.exchange.setModal(MODAL.NONE)}
                            />

            case MODAL.MAINTENANCE: 
            return <CloseableModal 
                        style={{}}
                        content={
                            <ComingSoon 
                                exchange={this.props.exchange}
                                />}
                        onCancel={()=>this.props.exchange.setModal(MODAL.NONE)}
                        />
            case MODAL.PASSWORD_CHANGE_SUCCESS: 
            return <CloseableModal 
                            style={{}}
                            content={
                                <PasswordChangeSuccess 
                                    exchange={this.props.exchange}
                                    />}
                        />
            case MODAL.MASTER_DATA_UPDATED: 
            return <CloseableModal 
                            style={{}}
                            content={
                                <MasterDataUpdatedSuccess 
                                    exchange={this.props.exchange}
                                    />}
                        /> 
        default: return null; 
        }
    }

    render() {
        return (
            <div className='modal-controller' style={{height:'0px'}}>
                {this.renderModals()}
            </div>
        );
    }
}

export default ModalController;