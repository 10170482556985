import React, { Component } from 'react';
import './LoginSignup.css'
import { getAuth } from "firebase/auth";
import {isMobile} from 'react-device-detect';
import {signInWithEmailAndPassword} from 'firebase/auth';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import history from '../../history';
import { LABELS } from '../../Labels';
import { MODAL } from '../../main/ModalController';
import AuthService from '../../service/AuthService';
import { CircularProgress } from '@mui/material';
const auth = getAuth();
class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
             email:''
            ,password:''

            ,onLoad:false
            ,errorMsg:''
            ,showPassword:false
            ,errInputs:''
            ,errorEmail:''
        }
    }

    async componentDidMount(){
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        switch(event.keyCode) {
            case 13: this.loginClicked();break; // ENTER KEY
            default: 
                break;
        }
    }

    changeInputHandler=(event)=>{
        let errInputs = this.state.errInputs;
        switch(event.target.id){
            case 'email-input': 
                errInputs = errInputs.replace(' email','');
                this.setState({email:event.target.value, errInputs:errInputs, errorMsg:'',errorEmail:''});
            break;
            case 'password-input':
                errInputs = errInputs.replace(' password','');
                this.setState({password:event.target.value, errInputs:errInputs, errorMsg:''});
            break;
            
            default:break;
        }
    }  

    validateEmail = (email)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    loginClicked=()=>{
        this.setState({onLoad:true},()=>{
            let errInputs ='';
            let errorEmail ='';
            if(this.state.email===''){
                errInputs=errInputs+' email';
            }
            if(this.state.password===''){
                errInputs=errInputs+' password';
            }

            if(this.validateEmail(this.state.email)===false){
                errInputs=errInputs+' email';
                errorEmail=LABELS.INVALID_EMAIL[(this.props.exchange.language || 'de')];
            }

            if(errInputs!=='' || errorEmail!==''){
                this.setState({onLoad:false,errInputs:errInputs,errorEmail:errorEmail});
            } else {
                this.login();
            }
        });
    }

    login=async()=>{
        let lang = this.props.exchange.language || 'de';
        signInWithEmailAndPassword(auth, this.state.email, this.state.password).then((res)=>{
            if(res.user.emailVerified){
                this.props.exchange.removeModal();
                if(window.location.pathname.includes('62d086cc-90ca-4fd1-b050-9c830a8f2a8c')){
                    history.push('/')
                    history.go()
                }
            }else{
                AuthService.logout(()=>{
                    this.setState({onLoad:false,errorMsg:LABELS.NOT_CONFIRMED[lang]});
                },false)
            }
        }).catch((error)=>{
            console.log('lang',lang)
            console.log('loginByEmail->login->signInWithEmailAndPassword: error', JSON.stringify(error));
            if(lang==='de'){
                switch(error.code){
                    case "auth/user-not-found": this.setState({onLoad:false,errorMsg:"Benutzer nicht gefunden"});break;
                    case "auth/invalid-credential":  this.setState({onLoad:false,errorMsg:"Ungültige E-Mail-Adresse oder ungültiges Passwortd"});break;
                    case "auth/invalid-email":  this.setState({onLoad:false,errorMsg:"Ungültige E-Mail-Adresse"});break;
                    case "auth/wrong-password": this.setState({onLoad:false,errorMsg:"Falsches Passwort"});break;
                    case "auth/network-request-failed":this.setState({onLoad:false,errorMsg:"Netzwerkfehler. Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut."}); break;
                    case "auth/invalid-login-credentials":this.setState({onLoad:false,errorMsg:LABELS.INCORRECT[lang]});break;
                    default:this.setState({onLoad:false,errorMsg:error.code});break;
                }
            } else {
                switch(error.code){
                    case "auth/user-not-found": this.setState({onLoad:false,errorMsg:"User not found"});break;
                    case "auth/invalid-credential":  this.setState({onLoad:false,errorMsg:"Invalid email address or password"});break;
                    case "auth/invalid-email":  this.setState({onLoad:false,errorMsg:"Invalid email address"});break;
                    case "auth/wrong-password": this.setState({onLoad:false,errorMsg:"Incorrect password"});break;
                    case "auth/network-request-failed":this.setState({onLoad:false,errorMsg:"Network error. Check your connection and try again."}); break;
                    case "auth/invalid-login-credentials":this.setState({onLoad:false,errorMsg:LABELS.INCORRECT[lang]});break;
                    default:this.setState({onLoad:false,errorMsg:error.code});break;
                }
            }
        }); 
}

    render() {
        let lang = this.props.exchange.language || 'de';
        return (
            <div className={isMobile?'login-page-mobile':'login-page'} >
                <div className='lp' style={{display:'flex',alignItems:'center',justifyContent:'center', height:'100%', width:'100%'}}>
                    <div className={'ls-component ' +(isMobile?'ls-component-mobile':'')} style={{
                        width:isMobile?'100%':'40vw',maxWidth:'600px', minWidth:isMobile?'':'450px',pointerEvents:this.state.onLoad?'none':''}}>

                        <LinearProgress sx={{marginBottom:'22px',opacity:this.state.onLoad?'1':'0'}}/>
                            <div style={{textAlign:'center'}}>
                                <h2>{LABELS.LOGIN[lang]}</h2>
                                <br/>
                                <p>{LABELS.LOGIN_MSG[lang]}</p>
                            </div>
                        <br/>
                        <br/>
                        <br/>
                        <p className='ititle'>{LABELS.USER_EMAIL[lang]}</p>
                        <TextField 
                            id="email-input" variant="outlined"
                            autoComplete='off'
                            inputProps={{form: {
                                    autocomplete: 'off'
                                    }
                                }}
                            error={this.state.errInputs.includes('email')}
                            helperText={this.state.errorEmail}
                            value={this.state.email} onChange={this.changeInputHandler}
                            sx={{marginBottom:'12px',width:'100%'}}/>
                    
                        <p className='ititle'>{LABELS.PASSWORD[lang]}</p>
                        <FormControl sx={{ m: 1, width: '100%',margin:'0px' }} variant="outlined">
                            {/* <InputLabel htmlFor="outlined-adornment-password">{LABELS.PASSWORD[lang]}</InputLabel> */}
                            <OutlinedInput
                                id="password-input"
                                error={this.state.errInputs.includes('password')}
                                value={this.state.password}
                                onChange={this.changeInputHandler} 
                                inputProps={{form: {
                                    autocomplete: 'off'
                                    }
                                }}
                                type={this.state.showPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{this.setState({showPassword:!this.state.showPassword});}}
                                        edge="end">
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            // label={LABELS.PASSWORD[lang]}

                            />
                        </FormControl>

                        <br/><br/>
                        <div style={{textAlign:'right',marginTop:'6px'}}>
                            <Link onClick={()=>this.props.exchange.setModal(MODAL.FORGOT_PASSWORD)}>{LABELS.FORGOT_PASS[lang]}</Link> 
                        </div>   
                        <br/>
                        <p style={{color:'red',padding:'10px 10px',textAlign:'center'}}>{this.state.errorMsg}</p>

                        <button className='btn1' onClick={this.loginClicked}>
                            {this.state.onLoad?
                                <CircularProgress size={'24px'} sx={{color:'white'}}/>:
                                <p>{LABELS.LOGIN[lang]}</p>}
                        </button>
                        
                        <div style={{textAlign:'center'}}>
                            <br/>
                            <span>{LABELS.NO_ACCOUNT_YET[lang]}</span>
                            <Link onClick={()=>this.props.exchange.setModal(MODAL.REGISTER)}>{LABELS.REGISTER[lang]}</Link>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default LoginPage;