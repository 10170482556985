import { LABELS } from '../../Labels';
import DocumentQuestionaire from './DocumentQuestionaire';
import {  useParams} from "react-router-dom";

export const DocQueWrapper = (props) => {
  let lang = props.exchange.language || 'de';
    const { par1,par2,par3 } = useParams();


    if(par2!=null && par3!=null){
      return (null)
    }else{

    let docId = par1;
   
    let data={
      "doc-0": 
        {
        id:'doc-0',
        title: "Datenerfassung - Übersicht",
        segments:[
          {
            id:'000-213-1231',
            type: "title",
            text: "Datenerfassung - Übersicht"
          },
          {
            id:'111-213-1231',
            type: "text",
            text:  "Betreffend der Datenverarbeitung beinhaltet die Datenschutzerklärung die nachfolgenden Punkte. Diese Punkte beinhalten die wichtigsten Prozesse einer Arztpraxis."
            +"\n\n • Behandlung der Patienten inkl. Krankenakte"
            +"\n • Abrechnung der Patientenkontakte an Versicherung oder direkt an Patient"
            +"\n • Zusammenarbeit und damit Weiterleitung von Patienten Daten mit einem Labor, einem Spital und Spezialisten"
            +"\n • Übergabe von Daten an ein Trustcenter sowie MASS Zahlen."
            +"\n • Mitarbeiter und deren damit zugehöriger Lohnadministration, Bewerbungen"
            +"\n • ITC: Zusammenarbeit mit einem Praxisinformationsanbieter, Verwendung der Microsoft Produkte"
            +"\n\n  Nachfolgende Dienstleistungen sind individuelle Leistungen, mit welcher Sie die Datenschutzerklärung auf Ihre Praxis personalisieren können:"        
          },
          {
            id:'factoring',
            type:"boolean",
            text:"Factoring"
          },
          {
            id:'inkasso',
            type:"boolean",
            text:"Inkasso"
          },
          {
              id:'nutzung',
                type:"boolean",
                text:"Nutzung einer Terminplattform",
                default: false
              },
              {
                  id:'belegarzt',
                    type:"boolean",
                    text:"Belegarzt",
                    default: false
                  },
              {
                id:'administration',
                type:"boolean",
                text:"Zusammenarbeit mit einer externen Administration"
              },
            ]
          ,actionButtons:[
            {
              id:'213-452342-12321',
              text:'Patientenformular (Word)',
              eventId:'0'
            },
            {
              id:'213-452342-12321',
              text:'Datenschutzerklärung Patient (PDF)',
              eventId:'1'
            },
            {
              id:'213-452342-12321',
              text:'Datenschutzerklärung Website (PDF)',
              eventId:'2'
            }
         ]
      }
  };

      return <DocumentQuestionaire exchange={props.exchange} data={data[docId]} />;

    }
  };